import _stream from "stream";
import _util from "util";
import _blob from "blob";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var WritableStream = _stream.Writable;
var util = _util;
var Blob = _blob;
var URL = _global.URL || _global.webkitURL || _global.mozURL;

function BlobStream() {
  if (!((this || _global) instanceof BlobStream)) return new BlobStream();
  WritableStream.call(this || _global);
  (this || _global)._chunks = [];
  (this || _global)._blob = null;
  (this || _global).length = 0;
}

util.inherits(BlobStream, WritableStream);

BlobStream.prototype._write = function (chunk, encoding, callback) {
  // convert chunks to Uint8Arrays (e.g. Buffer when array fallback is being used)
  if (!(chunk instanceof Uint8Array)) chunk = new Uint8Array(chunk);
  (this || _global).length += chunk.length;

  (this || _global)._chunks.push(chunk);

  callback();
};

BlobStream.prototype.toBlob = function (type) {
  type = type || "application/octet-stream"; // cache the blob if needed

  if (!(this || _global)._blob) {
    (this || _global)._blob = new Blob((this || _global)._chunks, {
      type: type
    });
    (this || _global)._chunks = []; // free memory
  } // if the cached blob's type doesn't match the requested type, make a new blob


  if ((this || _global)._blob.type !== type) (this || _global)._blob = new Blob([(this || _global)._blob], {
    type: type
  });
  return (this || _global)._blob;
};

BlobStream.prototype.toBlobURL = function (type) {
  return URL.createObjectURL(this.toBlob(type));
};

exports = BlobStream;
export default exports;